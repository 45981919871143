import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'components/antd'
import getConfig from 'next/config'
import styled from 'styled-components'
import { ConfigProvider } from 'antd'

const TimeModal = styled(Modal)`
  .ant-modal-title {
    color: #1c1d1f;
    font-family: 'PingFang SC';
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    margin-bottom: 24px;
  }

  .modal-content {
    & > p {
      color: #333;
      font-family: 'PingFang SC';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 40px;
    }

    & > div {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      gap: 16px;
      align-self: stretch;

      & > button {
        flex: 1;
        font-weight: 500;
      }
    }
  }

  .ant-modal-content {
    border-radius: 8px;
  }
`

interface TimeWarningModalProps {
  time: number
  onClose?: () => void
}

export const TimeWarningModal: React.FC<TimeWarningModalProps> = ({ time, onClose: propOnClose }) => {
  const [isVisible, setIsVisible] = useState(false)
  const [warningText, setWarningText] = useState('')
  const [storageKey, setStorageKey] = useState(`password_warning_closed_${time}`)
  // const router = useRouter()
  // 使用localStorage来存储用户是否已经关闭过弹窗
  const handleClose = () => {
    setIsVisible(false)
    localStorage.setItem(storageKey, 'true')
    propOnClose?.()
  }

  const handleModify = () => {
    // 跳转到修改密码页面
    const { publicRuntimeConfig } = getConfig()
    const { PASSPORT_MODIFY_PASSWORD_URL } = publicRuntimeConfig
    const a = document.createElement('a')
    a.href = `${PASSPORT_MODIFY_PASSWORD_URL}&callback=${encodeURIComponent(window.location.origin + '/welcome')}`
    a.click()
    handleClose()
  }

  useEffect(() => {
    if (!time) return

    // 检查是否已经关闭过弹窗
    const currentTime = Date.now()

    const ninetyDay = 24 * 90 * 60 * 60 * 1000
    const diff = time + ninetyDay - currentTime

    const oneDay = 24 * 60 * 60 * 1000
    // const twoDay = oneDay * 2
    const threeDay = oneDay * 3
    // const sixDay = oneDay * 6
    const sevenDay = oneDay * 7

    const storageKeyOne = `password_warning_closed_${oneDay}`
    const storageKeyThree = `password_warning_closed_${threeDay}`
    const storageKeySeven = `password_warning_closed_${sevenDay}`

    if (diff <= oneDay) {
      const hasClosedBefore = localStorage.getItem(storageKeyOne)
      if (hasClosedBefore === 'true') return
      setWarningText('1天')
      setIsVisible(true)
      setStorageKey(storageKeyOne)
    } else if (diff <= threeDay && diff > oneDay) {
      const hasClosedBefore = localStorage.getItem(storageKeyThree)
      if (hasClosedBefore === 'true') return
      const dayNum = Math.ceil(diff / oneDay)
      setWarningText(`${dayNum}天`)
      setIsVisible(true)
      setStorageKey(storageKeyThree)
    } else if (diff <= sevenDay && diff > threeDay) {
      const hasClosedBefore = localStorage.getItem(storageKeySeven)
      if (hasClosedBefore === 'true') return
      const dayNum = Math.ceil(diff / oneDay)
      setWarningText(`${dayNum}天`)
      setIsVisible(true)
      setStorageKey(storageKeySeven)
    } else {
      localStorage.setItem(storageKeyOne, 'false')
      localStorage.setItem(storageKeyThree, 'false')
      localStorage.setItem(storageKeySeven, 'false')
    }
  }, [time])

  return isVisible ? (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            primaryColor: '#fff',
            colorPrimary: '#F74640',
            colorPrimaryHover: '#F74640dd',
            defaultActiveColor: '#F74640',
            colorPrimaryActive: '#c23322',
            defaultColor: '#1C1D1F',
            defaultBorderColor: '#DCDCDC',
            fontSize: 14,
            fontWeight: 500,
          },
        },
      }}
    >
      <TimeModal
        open={isVisible}
        onCancel={() => {
          handleClose()
        }}
        title="密码即将过期"
        footer={null}
        width={480}
      >
        <div className="modal-content">
          <p>贵公司已启用90天“定期修改密码”功能，请在{warningText}内修改你的邮箱密码，逾期密码将自动失效。</p>
          <div>
            <Button onClick={handleClose}>取消</Button>
            <Button type="primary" onClick={handleModify}>
              修改密码
            </Button>
          </div>
        </div>
      </TimeModal>
    </ConfigProvider>
  ) : null
}

export default TimeWarningModal
