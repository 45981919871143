/* eslint-disable */
import axios, { AxiosInstance } from 'axios'
import { cacheAdapterEnhancer, throttleAdapterEnhancer } from 'axios-extensions'
import getConfig from 'next/config'
import download from 'downloadjs'
import { toast } from 'shared/actions'
import { events } from 'helpers/event-emitter'
import { canUseDOM, blob2Obj } from '../helpers/util'

const {
  publicRuntimeConfig: { API_TIMEOUT_INTERVAL, BASE_URL, AI_TOOL_URL, HELLORF_BASE_URL },
} = getConfig()

const instanceAiTool = axios.create({
  baseURL: BASE_URL,
  adapter: throttleAdapterEnhancer(
    cacheAdapterEnhancer(axios.defaults.adapter, {
      enabledByDefault: false,
      cacheFlag: 'useCache',
    })
  ),
  timeout: API_TIMEOUT_INTERVAL,
  timeoutErrorMessage: '请求超时 请重试',
})

const instancePlus = axios.create({
  baseURL: BASE_URL,
  headers: { 'Cache-Control': 'no-cache' },
  adapter: throttleAdapterEnhancer(
    cacheAdapterEnhancer(axios.defaults.adapter, {
      enabledByDefault: false,
      cacheFlag: 'useCache',
    })
  ),
  timeout: API_TIMEOUT_INTERVAL,
  timeoutErrorMessage: '请求超时 请重试',
})

const instanceHellorf = axios.create({
  baseURL: BASE_URL,
  headers: { 'Cache-Control': 'no-cache' },
  adapter: throttleAdapterEnhancer(
    cacheAdapterEnhancer(axios.defaults.adapter, {
      enabledByDefault: false,
      cacheFlag: 'useCache',
    })
  ),
  timeout: API_TIMEOUT_INTERVAL,
  timeoutErrorMessage: '请求超时 请重试',
})

function interceptors(instance: AxiosInstance) {
  // 不显示错误提示的接口URL
  const ignoreUrls = []
  // 以下接口错误不提示Toast
  const ignorePaths = [
    '/passport',
    '/image/similar',
    '/image/show',
    '/image/simi-search/upload',
    '/index/apply',
    '/user/destroy',
    '/purchases/export',
    '/dam/clue',
    '/index/quotation',
  ]

  instance.interceptors.request.use(
    (config) => {
      // eslint-disable-next-line no-param-reassign
      config.withCredentials = true

      if (
        config.url.indexOf('index/quotation/download') !== -1 ||
        config.url.indexOf('user/members/export') !== -1 ||
        config.url.indexOf('purchases/export') !== -1
      ) {
        // eslint-disable-next-line no-param-reassign
        config.responseType = 'blob'
      }

      return config
    },
    (error) => Promise.reject(error)
  )

  instance.interceptors.response.use(
    async (response) => {
      // 下载
      if (canUseDOM() && response.data instanceof window.Blob && response.headers['content-disposition']) {
        const filename = response.headers['content-disposition'].replace(/attachment; filename="/, '').replace(/"/, '')

        return download(response.data, decodeURIComponent(filename))
      }

      const { url } = response?.config || {}

      // 异常
      if (
        response.status !== 200 ||
        !response.data ||
        typeof response.data !== 'object' ||
        // eslint-disable-next-line no-prototype-builtins
        !(
          response.data?.hasOwnProperty('result') ||
          response.data?.data?.hasOwnProperty('result') ||
          response.data?.msg === 'OK'
        )
      ) {
        try {
          const res: any = await blob2Obj(response.data)
          if (ignoreUrls.every((path) => url.indexOf(path) === -1)) {
            toast(res?.message || response?.data?.msg || `${response.status}：系统错误，请稍后重试`)
          }
          return await Promise.reject(res || response.data)
        } catch (err) {
          return Promise.reject(err || response.data)
        }
      }

      // 正常逻辑
      const { result, message, error_code } = response.data
      if (!result) {
        if (canUseDOM()) {
          // dam 权限关闭/过期
          if (
            error_code === 798 &&
            window.location.pathname !== '/dam' &&
            window.location.pathname !== '/dam/introduction'
          ) {
            window.location.href = '/dam'
            return response.data
          }
          // 被冻结帐号
          if (error_code === 999) {
            return response.data
          }
          // 登陆状态失效
          if (error_code === 302 || message === '请登录') {
            events.emit('LOGOUT')
            return response.data
          }
          // 亚特登录的 error code 4011 代表过期
          if (error_code === 4011) {
            events.emit('LOGOUT')
            return response.data
          }

          // 企业名称搜索
          if (error_code === 1 && message === '未搜索到结果' && url.indexOf('/user/user-warrant/search-company')) {
            return Promise.reject(response.data)
          }
          // 除ignorePaths之外的api toast提示
          if (ignorePaths.every((path) => url.indexOf(path) === -1) && message) {
            toast(message)
          }
        }
      }
      // console.log('xxx response.data', response.data)
      return response.data
    },
    (error) => {
      if (error.code === 'ECONNABORTED') {
        // 上报链接超时的错误到Sentry
        // 后端没有调整超时的策略 前端进行上传后无法处理 -> 上传没有意义 -> 暂不上传
        // const errMsg = `Timeout: ${error.message} ${error.config.url} ${JSON.stringify(error.config.params)}`
        // Sentry.captureMessage(errMsg)
        // 已购素材导出 超时不toast
        const { url } = error.config

        if (ignoreUrls.includes(url)) {
          return Promise.reject(error)
        }

        if (url.indexOf('/purchases/export') === -1) {
          toast(error.message)
        }

        return Promise.resolve({
          result: false,
          message: error.code,
        })
      }

      return Promise.reject(error)
    }
  )
  return instance
}

const API = interceptors(instancePlus)
const HELLORFAPI = interceptors(instanceHellorf)
const AITOOLAPI = interceptors(instanceAiTool)
API.CancelToken = axios.CancelToken

export default API
export { HELLORFAPI, AITOOLAPI }
